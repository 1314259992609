// ** React Imports
import { useState } from 'react'

// ** Next Imports
import Link from 'next/link'
import Image from 'next/image'

// ** MUI Components
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import useMediaQuery from '@mui/material/useMediaQuery'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Configs
import themeConfig from 'src/configs/themeConfig'

// ** Hooks
import { useAuth } from 'src/hooks/useAuth'
import useBgColor from 'src/@core/hooks/useBgColor'
import { useSettings } from 'src/@core/hooks/useSettings'
import { useContext } from 'react'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'
import { AuthContext } from 'src/context/AuthContext.js'

// ** React Router
import { useRouter } from 'next/router'

// ** Styled Components
const BoxWrapper = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: 450,
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: '0.18px',
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(6)
}))

const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required')
})

const defaultValues = {
  password: '',
  email: ''
}

const LoginPage = () => {
  const router = useRouter()
  const [rememberMe, setRememberMe] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const { emailLogin } = useContext(AuthContext)

  // ** Hooks
  const auth = useAuth()
  const theme = useTheme()
  const bgColors = useBgColor()
  const { settings } = useSettings()

  // ** Vars
  const { skin } = settings

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    setIsLoading(true)
    setLoginError('')

    const { email, password } = data
    if (email && password) {
      // Using emailLogin from AuthProvider context
      emailLogin(email, password)
        .then(response => {
          setIsLoading(false)
          if (response instanceof Error) {
            setLoginError(response.message || 'Login failed. Please check your credentials.')
            setError('email', {
              type: 'manual',
              message: response.message
            })
          } else {
            router.push('/') // Navigate to the homepage or dashboard after successful login
          }
        })
        .catch(err => {
          setIsLoading(false)
          setLoginError(err.message || 'An unexpected error occurred')
        })
    } else {
      setIsLoading(false)
      setLoginError('Email and password are required')
      console.error('Email or password is missing')
    }
  }

  const handleGoogleLogin = () => {
    setIsLoading(true)
    setLoginError('')
    auth.login().catch(err => {
      setIsLoading(false)
      setLoginError(err.message || 'Google login failed')
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme =>
          `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
      }}
    >
      <Container maxWidth='sm'>
        <BoxWrapper elevation={6}>
          <LogoWrapper>
            <Image
              src='/images/hellochef_logo.jpeg'
              alt='HelloChef Logo'
              width={120}
              height={120}
              style={{ borderRadius: '8px' }}
            />
          </LogoWrapper>
          <Box sx={{ mb: 6, textAlign: 'center' }}>
            <TypographyStyled variant='h5'>{`Welcome to ${themeConfig.templateName}! 👋🏻`}</TypographyStyled>
            <Typography variant='body2'>Please sign-in to your account and start the adventure</Typography>
          </Box>

          {loginError && (
            <Alert severity='error' sx={{ mb: 4 }}>
              {loginError}
            </Alert>
          )}

          <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    label='Email'
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.email)}
                    placeholder='info@expertservices.io'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Icon icon='mdi:email-outline' fontSize={20} />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                Password
              </InputLabel>
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    label='Password'
                    onChange={onChange}
                    id='auth-login-v2-password'
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    startAdornment={
                      <InputAdornment position='start'>
                        <Icon icon='mdi:lock-outline' fontSize={20} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: 'error.main' }} id=''>
                  {errors.password.message}
                </FormHelperText>
              )}
            </FormControl>
            <Box
              sx={{ mb: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
              <FormControlLabel
                label='Remember Me'
                control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
              />
              <Typography
                variant='body2'
                component={Link}
                href='/forgot-password'
                sx={{ color: 'primary.main', textDecoration: 'none' }}
              >
                Forgot Password?
              </Typography>
            </Box>
            <Button
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{
                mb: 2,
                borderRadius: '10px',
                boxShadow: theme => `0 8px 16px 0 ${theme.palette.primary.main}40`
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color='inherit' /> : 'Login'}
            </Button>
          </form>
          <Button
            fullWidth
            size='large'
            variant='outlined'
            onClick={handleGoogleLogin}
            sx={{
              mb: 7,
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              <>
                <Icon icon='mdi:google' fontSize={20} />
                Login with Google
              </>
            )}
          </Button>
          <Divider
            sx={{
              '& .MuiDivider-wrapper': { px: 4 },
              mt: theme => `${theme.spacing(5)} !important`,
              mb: theme => `${theme.spacing(7.5)} !important`
            }}
          >
            or
          </Divider>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            <Typography sx={{ mr: 2, color: 'text.secondary' }}>New on our platform?</Typography>
            <Typography
              href='/register'
              component={Link}
              sx={{
                color: 'primary.main',
                textDecoration: 'none',
                fontWeight: 600
              }}
            >
              Create an account
            </Typography>
          </Box>
        </BoxWrapper>
      </Container>
    </Box>
  )
}

LoginPage.getLayout = page => <BlankLayout>{page}</BlankLayout>
LoginPage.guestGuard = true

export default LoginPage
